export const categories = [
    {
      name: 'Metal',
      image: require("../assets/metal.jpg")
    },
    {
      name: 'Rock',
      image: require("../assets/rock.jpg")
    },
    {
      name: 'Pop',
      image: require("../assets/pop.jpg")
    },
    {
      name: 'Hip-Hop',
      image: require("../assets/hiphop.jpg")
    },
    {
      name: 'Latin',
      image: require("../assets/latin.jpg")
    },
    {
      name: 'Dance-Electronic',
      image: require("../assets/danceelectronic.jpg")
    },
    {
      name: 'R&B',
      image: require("../assets/randb.jpg")
    },
    {
      name: 'Country',
      image: require("../assets/country.jpg")
    }, {
      name: 'Folk-Acoustic',
      image: require("../assets/folkacoustic.jpg")
    },
    {
      name: 'Classical',
      image: require("../assets/classical.jpg")
    }, {
      name: 'Jazz',
      image: require("../assets/jazz.jpg")
    }, 
    {
      name: 'New Age',
       image: require("../assets/newage.jpg")
    },
    {
      name: 'Blues',
       image: require("../assets/blues.jpg")
    },
  ];
  