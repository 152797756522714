import api from "./api";

const createUser = (data) => {
  return api.post(`/user/signup` , data);
};

const getUserData = () => {
  return api.get(`/user/user/`)
}

const loginUser = (data) => {
  return api.post(`/user/signin` , data);
}

const markNotificationRead = (id) => {
  return api.post(`/user/notification/${id}`);
}

const UserService = {
  createUser,
  getUserData,
  loginUser,
  markNotificationRead,
};

export default UserService;
