import {
    ERROR_USER,
    LOADING_USER,
    SET_USER,
    MARK_NOTIFICATION_READ
  } from "../types";

  import axios from 'axios'

  import api from '../../api/api';

  import UserService from "../../api/users";
    
  // login user
  export const loginUser = (data) => async (dispatch) => {

    dispatch({ type: LOADING_USER });

    try {
      let res = await UserService.loginUser(data);
      setAuthorizationHeader(res.data.accessToken);

      let res2 = await UserService.getUserData();
 
      dispatch({
        type: SET_USER,
        payload: res2.data,
      });

      // dispatch(getUserData());
      // window.location.pathname = "/";
    
   } catch (err) {
     if(err?.code === 'ERR_NETWORK'){
       dispatch({
         type: ERROR_USER,
         payload: 'Network error, please try again'
       });
     }else{
       dispatch({
         type: ERROR_USER,
         payload: err.response.data.message,
       });
     }
   }
  }

  export const getUserData = (token) => async (dispatch) => {

    dispatch({ type: LOADING_USER });

    setAuthorizationHeader(token);

    try {
      let res = await UserService.getUserData();
 
     dispatch({
       type: SET_USER,
       payload: res.data,
     });
      
   } catch (err) {
     if(err?.code === 'ERR_NETWORK'){
       dispatch({
         type: ERROR_USER,
         payload: 'Network error, please try again'
       });
     }else{
       dispatch({
         type: ERROR_USER,
         payload: err?.response?.data?.message,
       });
     }
   }
};

export const markNotificationRead = (id) => async (dispatch) => {
  try{

    let res = await UserService.markNotificationRead(id);

    dispatch({
      type: MARK_NOTIFICATION_READ,
      payload: res.data
    })

  }catch(err){
    console.log(err);
  }
}

const setAuthorizationHeader = (token) => {
  const tokenToSet = `Bearer ${token}`;
  // localStorage.setItem("token", tokenToSet);
  api.defaults.headers.common["Authorization"] = tokenToSet;
};

  // create user
  // export const createUser = (data) => async (dispatch) => {
  //   dispatch({ type: LOADING_USER })
  //   let res;
  //   try {
  //      res = await UserService.createUser(data);
  
  //     dispatch({
  //       type: CREATE_USER,
  //       payload: res.data,
  //     });
      
  //     window.location.pathname = "/signin";
  
  //   } catch (err) {
  //     if(err?.code === 'ERR_NETWORK'){
  //       dispatch({
  //         type: ERROR_USER,
  //         payload: 'Network error, please try again'
  //       });
  //     }else{
  //       dispatch({
  //         type: ERROR_USER,
  //         payload: err.response.data.message,
  //       });
  //     }
  //   }
  // };