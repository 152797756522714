// react
import React from "react";
import { useNavigate } from "react-router-dom";
// assets
import shareVideo from "../assets/main.mp4";
import logo from "../assets/viny.png";

const Login = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-start items-center flex-col h-screen">
      <div className=" relative w-full h-full">
        <video
          src={shareVideo}
          type="image"
          loop
          controls={false}
          muted
          autoPlay
          className="w-full h-full object-cover"
        />

        <div className="absolute flex flex-col justify-center items-center top-0 right-0 left-0 bottom-0 bg-blackOverlay">
          <div className="p-5 custom-logo">
            <img src={logo} width="512px" alt="logo" />
          </div>

          <h1 className="site-description absolute flex flex-col justify-center items-center w-2/3 mb-5">
            <strong>Welcome to vinylovers.net</strong>
          </h1>
          
          <h2 className="site-description absolute flex flex-col justify-center items-center w-2/3">
            <br />
            <br />
            <br />
            <br />
            the ultimate online community for vinyl enthusiasts around the
            world.
            {/* Here, you can share your passion for music and vinyl by sharing pictures of your vinyl collection and a 
          brief description of each record. 
          Whether you're a long-time collector or just starting out, our platform is the perfect place to connect with other vinyl lovers, discover new albums, and showcase your own unique taste in music. 
          Join us today and share your love for vinyl with a community that understands and appreciates it like no one else. */}
          </h2>

          <div className="mainPageButtons-main">
            {/* <button
              onClick={() => navigate("/Signin")}
              className="bg-blue-500  text-white font-bold py-2 px-4 rounded"
            >
              Login!
            </button>
            <button
              onClick={() => navigate("/")}
              className="bg-blue-500  text-white font-bold py-2 px-4 rounded"
            >
              Check it Out!
            </button> */}
            <p className="site-description">Coming Soon</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
