import {
  LOADING_USER,
  ERROR_USER,
  CLEAR_ERROR_USER,
  SET_AUTHENTICATED,
  SET_USER,
  LIKE_VINYL,
  UNLIKE_VINYL,
  FAVORITE_VINYL,
  SET_UNAUTHENTICATED,
  MARK_NOTIFICATION_READ
} from "../types";

const initialState = {
  user: [],
  loading: false,
  error: "",
  authenticated: false,
  likes: [],
  favorites: [],
  notifications : [],
  username: '',
  ID: '',
  email: '',
  refreshToken : '',
  accessToken: '',
  image : '',
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_USER:
      return {
        ...state,
        loading: true,
      };
    case SET_USER:
      return {
        authenticated: true,
        username: action.payload.username,
        email: action.payload.email,
        ID: action.payload.ID,
        loading: false,
        refreshToken : action.payload.refreshToken,
        likes: [...action.payload.likes],
        favorites: [...action.payload.favorites],
        notifications: [...action.payload.notifications],
        image : action.payload.image,
        accessToken: action.payload.accessToken
      };
      case MARK_NOTIFICATION_READ:
        let index = state.notifications.findIndex((notification) => notification._id === action.payload._id);

        state.notifications[index].read = true;

        return { ...state };
    case ERROR_USER:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      case CLEAR_ERROR_USER:
        return {
          ...state,
          loading: false,
          error: ''
        };
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
      case SET_UNAUTHENTICATED:
        return initialState;
        
    case LIKE_VINYL:
      return {
        ...state,
        // spread the likes and add a new one on like
        likes: [
          ...state.likes,
          {
            username: "Hisham",
            vinylID: payload._id,
            userID: "6433cd741dbc07fbd584ac02",
            createdAt: "2023-04-10T12:18:29.536Z",
          },
        ],
      };
    case UNLIKE_VINYL:
      return {
        ...state,
        likes: state.likes.filter(
          (item) => item.vinylID !== action.payload._id
        ),
        ...state.likes,
      };

    case FAVORITE_VINYL:
      return {
        ...state,
        favorites: [
          ...state.favorites,
          {
            username: "Hisham",
            vinylID: payload._id,
            userID: "6433cd741dbc07fbd584ac02",
            createdAt: "2023-04-10T12:18:29.536Z",
          },
        ],
      };
    default:
      return state;
  }
};

export default userReducer;