import axios from 'axios';
// import { BASE_URL } from '../utils/constants';

// const BASE_URL = 'https://vinylovers-api.onrender.com/'
const BASE_URL = 'http://localhost:5000'

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
});

api.interceptors.request.use(function (config) {
      // config.headers.Authorization = accessToken;
      return Promise.resolve(config);

}, function (error) {
  return Promise.reject(error);
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true
});

export default api;