// react
import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
// components
import Login from "../src/components/Login";
import Register from "../src/components/Register";
import Home from "./components/container/Home";
import Signin from "./components/Signin";
import Users from "./components/Users";
// import RequireAuth from "./components/RequireAuth";
import PersistLogin from "./components/PersistLogin";
import AuthRoute from "./utils/AuthRoute";
// redux
import store from "./redux/store";
import { SET_UNAUTHENTICATED } from "./redux/types";
import { getUserData } from "./redux/actions/users";
// axios
import axios from "axios";
// jwt decode
import jwtDecode from "jwt-decode";
// hooks
import useRefreshToken from "./hooks/useRefreshToken";
import useLogout from "./hooks/useLogout";

const App = () => {
  const navigate = useNavigate();
  const refresh = useRefreshToken();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
}

  useEffect( () => {
    // axios.get("https://vinylovers-api.onrender.com/").then((res) => {
    //   console.log(res.data);
    // });

    refresh().then((res) => {
      if (res) {
        const decodedToken = jwtDecode(res);
        if (decodedToken.exp * 1000 < Date.now()) {
          store.dispatch({ type: SET_UNAUTHENTICATED });
          signOut();
          navigate("/Login");
        } else {
          const tokenToSet = `Bearer ${res}`;
          axios.defaults.headers.common["Authorization"] = tokenToSet;
          store.dispatch(getUserData(res));
          navigate("/");
        }
      } else {
        navigate("/Login");
      }
    },
  )}, []);

  return (
    // <Routes>
    //   <Route path="/login" element={<Login />} />
    //   <Route path="/register" element={<Register />} />
    //   <Route path="/signin" element={<Signin />} />
    //   <Route element={<PersistLogin />}>
    //     <Route path="*" element={<Home />} />
    //     <Route path="/users" element={<Users />} />
    //   </Route>
    // </Routes>
    <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="/register" element={<Login />} />
    <Route path="/signin" element={<Login />} />
    <Route element={<Login />}>
      <Route path="*" element={<Login />} />
      <Route path="/users" element={<Login />} />
    </Route>
  </Routes>
  );
};

export default App;