//vinyls
export const CREATE_VINYL = "CREATE_VINYL";
export const UPDATE_VINYL = "UPDATE_VINYL";
export const DELETE_VINYL = "DELETE_VINYL";
export const GET_VINYLS = "GET_VINYLS";
export const GET_VINYL = "GET_VINYL";
export const LOADING_VINYLS = "LOADING_VINYLS";
export const LIKE_VINYL = "LIKE_VINYL";
export const UNLIKE_VINYL = "UNLIKE_VINYL";
export const FAVORITE_VINYL = "FAVORITE_VINYL"
export const UNFAVORITE_VINYL = "UNFAVORITE_VINYL"

//UI
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const LOADING_DATA = 'LOADING_DATA';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';

//users
export const CREATE_USER = 'CREATE_USER';
export const LOADING_USER = 'LOADING_USER';
export const ERROR_USER = 'ERROR_USER';
export const CLEAR_ERROR_USER = 'CLEAR_ERROR_USER';
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const MARK_NOTIFICATION_READ = ' MARK_NOTIFICATION_READ';