import axios from 'axios'
import {BASE_URL} from '../utils/constants';
import Cookies from 'js-cookie';
const useLogout = () => {
    let refreshToken = Cookies.get('jwt');
    const logout = async () => {
        try {
            Cookies.remove('jwt');
            await axios.post(`${BASE_URL}/user/logout/${refreshToken}`, {
                withCredentials: true
            }).then((res)=>{
                
            })
        } catch (err) {
            console.error(err);
        }
    }

    return logout;
}

export default useLogout