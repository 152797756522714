import {
  CREATE_VINYL,
  GET_VINYLS,
  LOADING_VINYLS,
  GET_VINYL,
  LIKE_VINYL,
  UNLIKE_VINYL
} from "../types";

const initialState = {
  vinyls: [],
  numberPages : '',
  currentPage: '',
  vinyl: {},
  loading: false,
};

const vinylReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_VINYLS:
      return {
        ...state,
        loading: true,
      };
    case CREATE_VINYL:
      return {
        ...state,
        vinyls: [payload, ...state.vinyls],
        loading: false
      };
    case GET_VINYLS:
      if(action.payload.currentPage === 1){
        return {
          ...state,
          vinyls: [...action.payload.vinyls],
          numberPages: action.payload.numberOfPages,
          currentPage : action.payload.currentPage,
          loading: false,
        };
      }else{
        return {
          ...state,
          vinyls: [...state.vinyls, ...payload.vinyls],
          numberPages: action.payload.numberOfPages,
          currentPage : action.payload.currentPage,
          loading: false,
        };
      }


    case GET_VINYL:
      return { ...state, vinyl: payload };

    case LIKE_VINYL: {
      // find the vinyl
      let index = state.vinyls.findIndex(
        (vinyl) => vinyl._id === action.payload._id
      );
      state.vinyls[index] = action.payload;
      // update opened vinyl like count, so that the opened vinyl will have same like count as vinyl in list
      if (state.vinyls[index]._id === action.payload._id) {
        state.vinyls[index].likeCount = action.payload.likeCount;
      }
      return {
        ...state,
        vinyls: [...state.vinyls]
      };
    }

    case UNLIKE_VINYL: {
      let index = state.vinyls.findIndex(
          vinyl => vinyl._id === action.payload._id
      );
      state.vinyls[index].likeCount = action.payload.likeCount;
      if (state.vinyl._id === action.payload._id)
          state.vinyls = { ...state.vinyls, ...action.payload };
      return {
          ...state,
          vinyls: [...state.vinyls]
      };
  }

    default:
      return state;
  }
};

export default vinylReducer;
