import api from '../api/api';
import useAuth from './useAuth';
import Cookies from 'js-cookie';

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    let refreshToken = Cookies.get('jwt');
    const refresh = async () => {
        const response = await api.get(`/user/refresh/${refreshToken}`, {
            withCredentials: true,
        })
        setAuth(prev => {
            // console.log(JSON.stringify(prev));
            // console.log(response.data.accessToken);
            return { ...prev, accessToken: response.data.accessToken }
        });
        return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;